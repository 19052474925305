<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
    HoverCardContent,
    type HoverCardContentProps,
    HoverCardPortal,
    useForwardProps,
} from "radix-vue";
import { installAntlerComponent } from "@/Utils/component";

export type PopoverVariant = "card" | "default";

const props = withDefaults(
    defineProps<HoverCardContentProps & {
        class?: HTMLAttributes["class"],
        variant?: PopoverVariant,
    }>(),
    {
        sideOffset: 4,
        variant: "default",
    },
);

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;
    return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);

const classes = {
    base: "z-50 max-w-full w-[320px] sm:w-[480px] max-w-screen rounded-md border bg-white p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
    variants: {
        variant: {
            card: "max-w-[320px] sm:max-w-[480px]",
            default: "max-w-[280px] sm:max-w-[320px]",
        }
    }
};

const variant = props.variant;

const { aClass } = installAntlerComponent("hover-card", {variant}, classes);
</script>

<template>
    <HoverCardPortal>
        <HoverCardContent
            v-bind="forwardedProps"
            :class="aClass()"
        >
            <slot />
        </HoverCardContent>
    </HoverCardPortal>
</template>
